$animation-speed: 0.25s

.footer
  position: relative
  top: 0
  left: 0
  width: 100%
  z-index: 3
  background-color: #fff
  padding: 42px 50px
  transition: all $animation-speed ease-in-out
  backface-visibility: hidden
  @media (max-width: 1199px)
    padding: 30px 15px
  @media (max-width: 991px)
    padding: 40px 15px
  @media (max-width: 767px)
    //padding: 16px 0
  &__inner
    position: relative
    .brief_wrapper
      position: absolute
      left: 0
      top: 0
      @media (max-width: 767px)
        position: relative
      a
        color: #252525
    .contacts
      text-align: center
      width: 290px
      margin: 0 auto
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      @media (max-width: 767px)
        text-align: left
        margin: 32px 0
      .address
        display: block
        margin: auto auto 8px auto
        @media (max-width: 767px)
          margin: 0 0 8px 0
      a
        color: #252525
        font-weight: bold
        &.mail
          transition: all $animation-speed ease-in-out
          box-shadow: inset 0 0 0 rgba(25, 105, 223, 0%), 0 1px 0 rgba(25, 105, 223, 40%)
          &:hover
            box-shadow: inset 0 0 0 rgba(25, 105, 223, 0%), 0 2px 0 #1969df
    .privat_policy
      position: absolute
      right: 0
      top: 0
      @media (max-width: 767px)
        position: relative
        display: inline-block
      a
        margin: 0 0 8px 0
        line-height: 24px
        font-size: 16px
        width: max-content
