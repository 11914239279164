.tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  * {
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-property: all;
  }

  &__item {
    padding: 13px 16px;
    box-sizing: border-box;
    text-transform: uppercase;
    color: #252525;
    background-color: #ededed;
    width: max-content;
    border-radius: 21px;
    letter-spacing: 0.75px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    cursor: pointer;

    &_active {
      background-color: rgba(25, 105, 223, 0.13);
      color: #1969df;
    }

    @media (hover: hover) {
      &:hover {
        background-color: rgba(25, 105, 223, 0.13);
        color: #1969df;
      }
    }
  }
}
