.competencies {
  gap: 64px;

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
    row-gap: 64px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 24px;

    h4 {
      font-weight: bold;
      line-height: 1.17;
    }
  }
}

#competencies-24 {
  padding: 200px 0;
}
