.stories-view {
  @media screen and (max-width: 720px) {
    padding: 0;

    &__close {
      z-index: 5;
      top: 26px;
      right: 10px;
      filter: invert(1);
    }
  }
}
