.story {
  @media screen and (min-width: 721px) and (max-width: 1439px) {
    row-gap: 0.28vw;
    width: 6.12vw;

    &__image {
      width: 6.12vw;
      height: 6.12vw;

      &:before {
        border-width: 0.28vw;
      }
    }
  }
}
