

.stories-view {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  z-index: 16;
  display: flex;
  align-items: center;

  &__close {
    width: 24px;
    height: 24px;
    background: url('./img/close.svg');
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
    z-index: 10;

    @media (hover: hover) {
      &:hover {
        opacity: 0.3;
      }
    }
  }

  * {
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-property: all;
  }
}
