@media screen and (min-width: 721px) {
  .slider-stories-single {
    aspect-ratio: 9/16;
    max-width: 100%;
    width: auto;
    height: auto;
  }
}

@media screen and (min-width: 721px) and (max-width: 1439px) {
  .slider-stories-single {
    .swiper-pagination {
      width: calc(100% - 1.67vw) !important;
      left: 0.84vw !important;
      top: 0.84vw !important;
    }

    &__header {
      top: 1.81vw;
      left: 0.84vw;
    }
  }
}
