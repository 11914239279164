.reviews {
  @media screen and (max-width: 991px) {
    &__ava {
      width: 200px !important;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 40px 0 93px;

    &__wrapper {
      grid-template-columns: 1fr;
      gap: 24px;
      padding: 16px;
    }

    &__ava {
      position: static !important;
    }

    &__text {
      p {
        margin-bottom: 0;
      }
    }

    .title {
      margin-bottom: 40px !important;
    }

    .slider-reviews {
      &__dots {
        top: auto;
        bottom: -55px;
        right: auto;
        left: calc(50% - 15px);
      }

      .slick-arrow {
        top: auto;
        bottom: -55px;
      }

      .slick-prev {
        right: auto;
        left: calc(50% - 68px);
      }
      .slick-next {
        right: calc(50% - 68px);
      }
    }
  }
}
