$animation-speed: 0.25s

.title_video_header
  .mobile_background
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-size: cover
    background-position: center
    opacity: 0
    @media (max-width: 575px)
      opacity: 1

.projects_list
  display: flex
  flex-wrap: wrap
  max-width: 1080px
  width: 100%
  margin: 0 auto
  .project
    width: calc(100% / 3)
    height: 500px
    background: #f4f4f4
    position: relative
    @media (max-width: 991px)
      width: calc(100% / 2)
      height: 444px
    @media (max-width: 575px)
      width: calc(100%)
    .hover
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      z-index: 1
      .category,
      .year
        position: absolute
        top: 24px
        z-index: 1
        font-size: 16px
        text-transform: lowercase
        color: #ffffff
        line-height: 1.5
        font-weight: 500
        opacity: 0
        transition: all $animation-speed ease-in-out
      .category
        left: 50%
        transform: translateX(-50%)
      .year
        right: 24px
      .tag_list
        position: absolute
        left: 0px
        right: 0px
        bottom: 19px
        z-index: 1
        display: flex
        flex-wrap: wrap
        justify-content: center
        opacity: 0
        transition: all $animation-speed ease-in-out
        span
          display: block
          font-size: 16px
          line-height: 1.5
          color: #ffffff
          text-transform: lowercase
          margin: 0 22px
      .bg
        position: absolute
        left: 0
        right: 0
        bottom: 0
        top: 0
        z-index: 0
        opacity: 0
        transition: all $animation-speed ease-in-out
    .cover
      position: absolute
      left: 0
      right: 0
      bottom: 0
      top: 0
      background-size: cover
      background-position: center
      z-index: 0
    .name
      position: absolute
      left: 24px
      right: 24px
      bottom: 24px
      font-size: 48px
      line-height: 1.17
      letter-spacing: 1px
      color: #ffffff
      text-align: center
      z-index: 2
      transition: all $animation-speed ease-in-out
      //word-wrap: break-word
      margin: 0
      & > div
        width: 100%
        height: 24px
        display: flex
        flex-wrap: wrap
        justify-content: center
        opacity: 0
        transition: all $animation-speed ease-in-out
        position: absolute
        bottom: 0
        @media (max-width: 767px)
          opacity: 1
          bottom: -24px
        span
          display: block
          line-height: 24px
          font-size: 16px
          font-family: Spectral
          font-style: italic
          font-weight: lighter
          padding-right: 28px
          position: relative
          letter-spacing: normal
          div
            position: absolute
            top: 0
            right: 0
            width: 24px
            height: 24px
    &:hover
      .name
        bottom: 50%
        transform: translateY(50%)
        & > div
          bottom: -24px
          width: 100%
          opacity: 1
      .hover
        .category,
        .year,
        .tag_list
          opacity: 1
        .bg
          opacity: 0.8
          @media (max-width: 991px)
            opacity: 0.2
    @media (max-width: 991px)
      .name
        bottom: 50%
        transform: translateY(50%)
      .hover
        .category,
        .year,
        .tag_list
          opacity: 1
        .bg
          opacity: 0.2
          background: #000!important

.tabs-projects
  max-width: 1080px
  width: 100%
  margin: 118px auto 60px
  @media (max-width: 1112px) 
    padding: 0 16px
    max-width: 100%
  @media (max-width: 767px) 
    padding: 0