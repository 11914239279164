$border: #007bff;
$borderTransparent: #fff;
$colorText: #252525;

.story {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 4px;
  width: 88px;

  &__image {
    border-radius: 50%;
    width: 88px;
    height: 88px;
    border: 2px solid $border;
    box-sizing: border-box;
    position: relative;
    background-size: cover;

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      border: 4px solid $borderTransparent;
      border-radius: 50%;
      background: transparent;
      position: absolute;
      top: 0;
      left: 0;
    }

    @media (hover: hover) {
      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &__name {
    hyphens: auto;
    word-wrap: break-word;
    width: 100%;
    text-align: center;
    color: $colorText;
  }
}
