.slider-stories-group {
  @media screen and (max-width: 720px) {
    padding: 0;

    &__close {
      z-index: 5;
      top: 26px;
      right: 10px;
      filter: invert(1);
    }

    &__volume {
      left: 20px;
      bottom: 20px;
      top: auto;

      &:after {
        content: none;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .slider-stories {
    width: 100%;
    height: 100%;
  }
}
