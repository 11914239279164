.logo-animate {
  width: 75px;
  height: 75px;
  clip-path: path(
    'M44 22.031C29.267 32.0053 21.9925 44 21.9925 44C21.9925 44 14.718 32.0053 0 22.031C14.718 13.9982 21.9925 0 21.9925 0C21.9925 0 24.9977 5.78142 31.039 12.0407L24.3041 11.9327L12.7612 32.6531H20.0047L31.039 12.0407C34.4139 15.5248 38.7292 19.1478 44 22.031Z'
  );
  position: absolute;
  top: -7px;
  left: 0;

  &__bg {
    width: 100%;
    height: 100%;
    background-image: url('./img/1.png');
    background-size: cover;
    animation: OborotHoverRotate 30s linear infinite;
    position: absolute;
    top:-10%;
    left:-10%;
  }
}

@keyframes OborotHoverRotate {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(360deg);
  }
  50% {
    transform: rotate(720deg);
  }
  75% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0);
  }
}
