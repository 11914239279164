.story-stub {
  aspect-ratio: 9/16;
  max-width: 80%;
  height: 80%;
  background: #3b3b3b;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.4;

  &__head {
    margin: 12px;
  }

  &__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
