.slider-stories-single {
  width: 100%;
  height: 100%;
  position: relative;

  &__slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: #000;
    position: relative;
  }

  &__content {
    width: 100%;
    aspect-ratio: 9/16;
    object-fit: contain;
  }

  &__prev,
  &__next {
    position: absolute;
    top: 0;
    width: 30%;
    height: 100%;
    z-index: 5;
  }

  &__prev {
    left: 0;
  }

  &__next {
    right: 0;
  }

  &__header {
    position: absolute;
    top: 26px;
    left: 12px;

    &_hide {
      opacity: 0;
    }
  }

  .swiper-pagination {
    display: flex;
    width: calc(100% - 24px) !important;
    left: 12px !important;
    top: 12px !important;
    bottom: auto !important;
    text-align: start;
    transform: none;
  }

  .swiper-pagination-bullet:only-child {
    display: inline-block !important;
  }

  &__dots {
    border-radius: 2px !important;
    height: 2px !important;
    margin: 0 1px !important;
    background: rgba(255, 255, 255, 0.5) !important;
    opacity: 1 !important;
    position: relative;

    &.swiper-pagination-bullet-active {
      > .slider-stories-single__dots-indicator {
        animation: Autoplay 5s linear forwards;
      }
    }
  }

  &__dots-indicator {
    width: 0;
    height: 2px;
    position: absolute;
    background-color: #fff;
    border-radius: 2px;
    transition-duration: 0s;
  }
}

@keyframes Autoplay {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
