.case_page
  .header
    position: relative
    height: calc(100vh - 89px)!important
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: center
    @media (max-width: 1199px)
      height: calc(100vh - 81px)!important
    @media (max-width: 767px)
      height: calc(100vh - 56px)!important
    .inner
      position: relative
      z-index: 2
      text-align: center
      max-width: 660px
      padding: 0 15px
      h1
        color: #ffffff
        line-height: 1.2
        letter-spacing: 1px
        margin: 0
      .link
        display: inline-block
        margin-top: 16px
        a
          color: #ffffff
          box-shadow: inset 0 0 0 #ffffff00, 0 1px 0 #ffffff66
          &:hover
            box-shadow: inset 0 0 0 #ffffff, 0 2px 0 #ffffff
      .description
        margin-top: 45px
        color: #ffffff
      .tag_list
        margin-top: 19px
        span
          font-weight: bold
          color: #ffffff
          margin: 5px 10px
          line-height: 1.5
          display: inline-block
    &.react-parallax
      &:before
        display: none
      img
        min-width: 100%!important
        min-height: 100%!important
        display: block
        
    .background
      position: absolute!important
      top: 0
      right: 0
      bottom: 0
      left: 0
      z-index: 0
      background-size: cover
      background-position: center

  .about
    padding: 120px 0
    @media (max-width: 767px)
      padding: 40px 0
    h2
      font-size: 34px
      line-height: 1.18
      letter-spacing: 0.25px
      @media (max-width: 767px)
        font-size: 24px
        margin-bottom: 24px
    .text
      &:last-child
        & > *
          margin-bottom: 0 !important
      p + p
        margin-top: 16px
    p
      font-size: 16px
      line-height: 1.5
    //& + .react-parallax
    //  height: 320px
    //  @media (max-width: 767px)
    //    height: 200px
    //  @media (max-width: 575px)
    //    height: 126px
    &__textList
      & > div
        p
          margin: 0
          line-height: 24px
          & + p
            margin-top: 8px
        & + div
          margin-top: 35px
          @media (max-width: 767px)
            margin-top: 28px
      .icon
        position: relative
        padding-left: 58px
        img
          position: absolute
          left: 0
          top: 50%
          transform: translateY(-50%)
    &--type_3
      h2
        margin-bottom: 24px
        @media (max-width: 767px)
          font-size: 24px
      .text
        & > * + *
          margin-top: 15px
      p
        font-size: 16px
        line-height: 24px
        margin: 0
        & + p
          margin-top: 16px
          @media (max-width: 767px)
            margin-top: 8px
    &.fullScreen
      padding: 0
      .container
        width: 100%
        max-width: 100%
        .col-12
          padding: 0
    ul
      padding-left: 16px
      li
        padding-left: 10px
        & + li
          margin-top: 10px
    .video
      video
        display: block
        margin: 0 auto
        max-width: 100%


  .targets
    padding: 120px 0
    @media (max-width: 767px)
      padding: 40px 0
    .title
      padding-left: 60px
      padding-bottom: 48px
      @media (max-width: 767px)
        padding-left: 24px
        padding-bottom: 24px
      h2
        margin: 0
        line-height: 1
    .targetList
      padding: 48px 60px
      @media (max-width: 767px)
        padding: 24px
      & > div
        position: relative
        padding-left: 53px
        min-height: 48px
        @media (max-width: 767px)
          padding-left: 44px
        & + div
          margin-top: 24px
        span
          position: absolute
          left: 0
          top: 0
          font-size: 48px
          font-weight: bold
          line-height: 1
          @media (max-width: 767px)
            font-size: 34px
        p
          font-size: 16px
          line-height: 24px
          margin: 0

  .prototypes
    .prototypes_list
      position: relative
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      overflow: hidden
      height: 718px
      @media (max-width: 991px)
        height: 600px
      @media (max-width: 767px)
        height: 500px
      @media (max-width: 575px)
        height: 192px
      & > div
        width: calc((100% / 4) - 24px)
        @media (max-width: 991px)
          width: calc((100% / 4) - 12px)
        @media (max-width: 767px)
          width: calc((100% / 4) - 6px)
        position: relative
        & > div
          img
            display: block
            width: 100%
          & + div
            margin-top: 24px
            @media (max-width: 991px)
              margin-top: 12px
            @media (max-width: 767px)
              margin-top: 6px
    &.offset
      position: relative
      .fade
        height: 260px
        left: 0
        right: 0
        bottom: 0
        position: absolute
        opacity: 1
        z-index: 1
        @media (max-width: 575px)
          height: 64px
      .prototypes_list
        height: 520px
        @media (max-width: 575px)
          height: 128px
        & > div
          &:nth-child(2)
            margin-top: 48px
            @media (max-width: 575px)
              margin-top: 12px
          &:nth-child(4)
            margin-top: 48px
            @media (max-width: 575px)
              margin-top: 12px
  .features
    padding: 120px 0
    @media (max-width: 767px)
      padding: 40px 0
    .item
      padding-top: 80px
      @media (max-width: 991px)
        padding-top: 40px
      @media (max-width: 767px)
        display: none
      .name
        margin-bottom: 26px
        @media (max-width: 991px)
          margin-bottom: 16px
        span
          font-size: 16px
          font-weight: 700
      .line
        position: relative
        div
          display: block
          width: 5px
          height: 5px
          background-color: #c2c2c2
          border-radius: 50%
        &:before
          content: ""
          display: block
          position: absolute
          top: 50%
          transform: translateY(-50%)
          left: 21px
          right: 0
          height: 1px
          background: #c2c2c2
        &:after
          content: ""
          display: block
          width: 50px
          height: 1px
          background-color: #d91d00
          position: absolute
          top: 50%
          transform: translateY(-50%)
          right: 0
      .text
        padding-top: 24px
        @media (max-width: 991px)
          padding-top: 16px
        p
          margin: 0
          & + p
            margin-top: 6px
    .image
      position: relative
      img
        display: block
        width: 100%

  .images_1-1
    .images
      position: relative
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      & > div
        width: calc((100% / 2) - 12px)
        @media (max-width: 767px)
          width: calc((100% / 2) - 6px)
        .mobile
          display: none
          @media (max-width: 767px)
            display: block
        .pc
          display: block
          @media (max-width: 767px)
            display: none
        .react-parallax
          height: 580px
          @media (max-width: 767px)
            height: 400px
          @media (max-width: 575px)
            height: 154px
          img
            position: absolute
        &:nth-child(1)
          .react-parallax
            img
              top: 0
              width: 100% !important
              height: auto!important
        &:nth-child(2)
          .react-parallax
            img
              bottom: -250px
              width: 100% !important
              height: auto!important
  .screens_3
    overflow: hidden
    .screens_list
      height: 580px
      position: relative
      @media (max-width: 767px)
        height: auto
      .screen
        height: calc(100% - 80px)
        @media (max-width: 767px)
          height: auto
        img
          width: 100%
        &-1
          position: relative
          top: 80px
          bottom: 0
          left: 0
          width: calc((100% / 2) - 12px)
          height: 500px
          z-index: 1
          overflow: hidden
          box-shadow: 0 20px 40px 0 rgba(37, 37, 37, 0.2)
          @media (max-width: 767px)
            position: absolute
            top: 64px
            bottom: initial
            left: 0
            width: 70%
            height: 205px
          //.react-parallax
          img
            top: 0
            @media (max-width: 767px)
              top: 0
        &-2
          position: absolute
          top: 0
          bottom: 0
          left: 28%
          width: calc((100% / 2) - 12px)
          height: 452px
          z-index: 0
          overflow: hidden
          @media (max-width: 767px)
            position: absolute
            top: 0
            bottom: initial
            right: 0
            left: initial
            width: 70%
            height: 187px
          //.react-parallax
          img
            bottom: 100px
            @media (max-width: 767px)
              bottom: 0
        &-3
          position: absolute
          top: 73px
          bottom: 74px
          right: 0
          width: 240px
          overflow: hidden
          height: auto
          @media (max-width: 767px)
            padding-top: 309px
            padding-bottom: 32px
            position: relative
            margin-left: auto
            margin-right: auto
            top: 0
            bottom: initial
          //.react-parallax
          img, video
            top: 0
            width: 100%!important
            height: auto!important
        .img
          height: 100%
          .react-parallax
            height: 100%
            width: 100%


  .screens_1-2
    .screens_list
      height: 580px
      position: relative
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      @media (max-width: 767px)
        height: 150px
      .screen
        height: 100%
        .img
          width: 100%
          height: 100%
          .react-parallax
            height: 100%
        &-1
          position: relative
          top: 0
          bottom: 0
          left: 0
          width: calc(60% - 12px)
          z-index: 1
          overflow: hidden
          .react-parallax
            img
              top: 0
        &-2
          position: relative
          top: 0
          bottom: 0
          width: calc(40% - 12px)
          z-index: 0
          .react-parallax
            img
              top: -50%
  .screens_greed
    height: 580px
    position: relative
    @media (max-width: 767px)
      height: 260px
    @media (max-width: 575px)
      height: 123px
    .react-parallax
      width: 100%
      height: 100%

  .verticalCenter
    @media (min-width: 768px)
      display: flex
      align-items: flex-start
      height: 100%
      & > div
        align-self: center
        width: 100%

  .slider_under_num
    position: relative
    padding-bottom: 53px
    margin: -24px -24px -48px
    width: calc(100% + 48px)
    height: calc(100% + 72px)
    &.onlyScreen
      margin-top: 0
      margin-bottom: 0
      .slide
        img
          &:first-child
            margin: 0 auto!important
    @media (max-width: 767px)
      margin: 0 -24px
    @media (max-width: 575px)
      margin: 0 -15px
      width: calc(100% + 30px)
    .slick-list
      //overflow: initial!important
      .slick-track
        //height: auto!important
    .popupsScreens
      position: absolute
      transform: translate(-50%, -50%)
      top: calc(50% + 24px)
      left: calc(50% + 24px)
      z-index: 1

    .slider-tabs
      .slick-dots
        margin-top: 40px
        ul
          margin: 0 auto
          padding: 0
          list-style-type: none
          display: flex
          justify-content: center
        li
          margin: 0 12px
          padding: 0

    .slide
      position: relative
      img
        display: block
        &:first-child
          width: calc(100% - 48px)
          margin: 24px auto 48px
          @media (max-width: 575px)
            width: calc(100% - 30px)
        &:not(:first-child)
          max-width: 50%
          position: absolute
          z-index: 1
          &.top
            top: 0
          &.left
            left: 0
          &.right
            right: 0
          &.bottom
            bottom: 0
    .counterSlides
      position: absolute
      bottom: 0
      font-family: Spectral
      font-style: italic
      width: 128px
      text-align: center
      font-weight: 500
      pointer-events: none
      margin-left: 26px
      .currentSlide
        font-size: 18px
        line-height: 24px
      .countSlides
        font-size: 14px
        line-height: 24px
    .slick-arrow,
    .sliderArrow
      cursor: pointer
      transition: all 0.15s
      position: absolute
      bottom: -53px
      left: 128px
      z-index: 9
      img
        will-change: transform
      &:hover
        //opacity: 0.25!important
      &.slick-prev,
      &.prevArrow
        transform: rotate(-180deg)
        left: 26px
    &.bigSlider
      margin: 0
      width: 100%
      height: 100%
      padding-top: 80px
      .slide
        img
          &:first-child
            width: 100%
      .counterSlides
        left: 50%
        margin-left: -64px
        bottom: 0
      .slick-prev
        left: 50%
        margin-left: -64px
      .slick-next
        left: initial
        right: 50%
        margin-right: -64px
  .text_and_slider
    .text
      .title
        margin-bottom: 24px
        h3
          margin: 0
      ul
        margin: 0
        padding-left: 17px
        li
          & + li
            margin-top: 15px

  .bigScreen
    padding: 120px 0
    @media (max-width: 767px)
      padding: 40px 0
    img
      width: 100%

  .pt-0
    padding-top: 0 !important

  .scrollScreen
    padding: 120px 0
    @media (max-width: 767px)
      padding: 40px 0
    .wrapperScreen
      position: relative
      img
        display: block
        width: 100%
      .scrollImage
        position: absolute
        top: 4.1%
        left: 4.0%
        right: 3.5%
        bottom: 33%
        overflow: auto
        img

  .react-parallax
    //height: 320px
    position: relative
    &:before
      content: ""
      display: block
      width: 100%
      padding-top: 24.615384615384615%
    //@media (max-width: 767px)
    //  height: 200px
    //@media (max-width: 575px)
    //  height: 126px
    img
      top: 0
    &.h-640
      &:before
        padding-top: 49.230769230769231%
  .review
    padding: 120px 0
    @media (max-width: 767px)
      padding: 40px 0
    &Wrapper
      display: flex
      flex-wrap: wrap
      @media (max-width: 767px)
        display: block
        position: relative
      .ava
        width: 200px
        padding-top: 64px
        @media (max-width: 991px)
          width: 100px
        @media (max-width: 767px)
          position: absolute
          top: 52px
          left: 0
          padding-top: 0
        div
          width: 100%
          background: #ccc
          border-radius: 50%
          background-size: cover
          position: relative
          &:before
            content: ""
            display: block
            width: 100%
            padding-top: 100%
          span
            font-size: 156px
            position: absolute
            top: -146px
            left: -24px
            color: #1969df
            font-weight: bold
            @media (max-width: 991px)
              font-size: 78px
              top: -73px
              left: 0
      .text
        width: calc(100% - 200px)
        padding-left: 40px
        @media (max-width: 991px)
          width: calc(100% - 100px)
        @media (max-width: 767px)
          width: 100%
          padding-left: 0
        h2
          font-size: 34px
          margin-bottom: 24px
          @media (max-width: 767px)
            font-size: 24px
            margin-bottom: 148px
        .name
          padding: 0 0 24px
          span
            display: block
            & + span
              opacity: 0.5
              a
                font-family: Spectral
                font-style: italic
                color: #252525
                border-bottom: 1px solid #1969df



  .col-3-images
    .images_list
      position: relative
      display: flex
      flex-wrap: wrap
      justify-content: space-between
      overflow: hidden
      height: 914px
      @media (max-width: 991px)
        height: 500px
      @media (max-width: 767px)
        height: 400px
      @media (max-width: 575px)
        height: 192px
      & > div
        width: calc((100% / 3) - 24px)
        @media (max-width: 991px)
          width: calc((100% / 3) - 12px)
        @media (max-width: 767px)
          width: calc((100% / 3) - 6px)
        position: relative
        //&:nth-child(2)
        //  margin-top: -100px
        //&:nth-child(4)
        //  margin-top: -100px
        & > div
          img
            display: block
            width: 100%
          & + div
            margin-top: 24px
            @media (max-width: 991px)
              margin-top: 12px
            @media (max-width: 767px)
              margin-top: 6px

  .text_and_slider .text_block + .slider_block
    margin-top: 40px

  .text_and_slider .slider_block + .text_block
    margin-top: 35px

  .slider-tabs
    padding-bottom: 0
    .slider-tabs__slider
      padding-bottom: 0
      @media (max-width: 767px)
        padding-top: 40px