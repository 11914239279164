.logo-animate {
  @media screen and (max-width: 991px) {
    width: 60px;
    height: 60px;
    clip-path: path(
      'M30 15.0211C19.9548 21.8218 14.9949 30 14.9949 30C14.9949 30 10.035 21.8218 0 15.0211C10.035 9.54422 14.9949 0 14.9949 0C14.9949 0 17.0439 3.94188 21.1629 8.20958L16.571 8.13596L8.7008 22.2635H13.6396L21.1629 8.20958C23.464 10.5851 26.4063 13.0553 30 15.0211Z'
    );
    top: 0;
  }
}
