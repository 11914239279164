.tabs {
  @media screen and (max-width: 767px) {
    overflow-x: auto;
    width: 100vw;
    transform: translateX(-10px);
    flex-wrap: nowrap;

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
  
    &__item {
      white-space: nowrap;

      &:first-child {
        margin-left: 16px;
      }

      &:last-child {
        margin-right: 16px;
      }
    }
  }
}
