.slider-gallery {
  @media screen and (max-width: 767px) {
    width: 100%;
    height: auto;

    &__arrows {
      display: none;
    }
  }
}

.slider-gallery-dots {
  @media screen and (max-width: 767px) {
    padding: 16px 0 40px !important;
    width: 100%;

    .swiper-slide {
      height: auto;
      aspect-ratio: 90/50;
    }
  }
}
