.slider-gallery {
  overflow: hidden;
  height: 483px;
  aspect-ratio: 859/483;

  &__title {
    text-align: center;
    margin-bottom: 60px;
  }

  .swiper-slide {
    margin: 0 !important;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  &__arrows {
    position: absolute;
    top: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 0;
  }

  &__arrow {
    background-image: url('./img/arrow.svg');
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: transparent;
    background-size: 18px;
    width: 48px;
    height: 48px;
    box-sizing: border-box;
    border: 1px solid #fff;
    border-radius: 50%;
    color: transparent;
    cursor: pointer;
    z-index: 1;
    opacity: 0.5;

    @media (hover: hover) {
      &:hover {
        opacity: 1;
      }
    }

    &_prev {
      margin-left: 24px;
    }

    &_next {
      margin-right: 24px;
      transform: rotate(180deg);
    }
  }

  &__dot {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.slider-gallery-dots {
  padding: 40px 0 120px !important;
  box-sizing: border-box;
  overflow: hidden !important;
  width: 639px;

  .swiper-slide {
    height: auto;
    aspect-ratio: 197/110;
    cursor: pointer;

    &.swiper-slide-active {
      box-shadow: 0 20px 40px 0 rgba(37, 37, 37, 0.2);
    }
  }
}
