.head-story {
  display: grid;
  grid-template-columns: 40px auto;
  column-gap: 8px;
  align-items: center;

  :last-child {
    justify-self: flex-start;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  &__avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  p {
    margin: 0;
    color: #fff;
  }
}
