.stories-block {
  @media screen and (max-width: 720px) {
    justify-content: flex-start;
    margin: 20px 0 15px;

    .story:nth-child(1) {
      margin-left: 20px;
    }

    .story:last-child {
      margin-right: 20px;
    }
  }
}
