$bg: #fff;

.page-story {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: $bg;
  z-index: 15;
}
