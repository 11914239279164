.stories-block {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  margin: 25px 0;
  padding-top: 5px;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  z-index: 1;

  &::-webkit-scrollbar-thumb {
    background-color: rgba(220, 226, 229, 0.5);
  }

  &::-webkit-scrollbar {
    height: 8px;
  }

  * {
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: 0s;
    transition-property: all;
  }
}
