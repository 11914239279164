@media screen and (min-width: 721px) and (max-width: 1439px) {
  .story-stub {
    &__head {
      margin: 0.84vw;
    }

    &__avatar {
      width: 2.78vw;
      height: 2.78vw;
    }
  }
}
