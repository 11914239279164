@media screen and (min-width: 721px) and (max-width: 1439px) {
  .head-story {
    grid-template-columns: 2.78vw auto;
    column-gap: 0.56vw;

    &__avatar {
      width: 2.78vw;
      height: 2.78vw;
    }
  }
}
